import _Button from "./Button";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSingleButton = void 0;
var Button_1 = _Button;
function getSingleButton(action, button, subgroups, updateCb) {
  action.addChild(button, action.group, subgroups);
  action.subscribeToChild(button, Button_1.Action.UPDATE, updateCb);
  return button.payload;
}
exports.getSingleButton = getSingleButton;
export default exports;
export const __esModule = exports.__esModule;
const _getSingleButton = exports.getSingleButton;
export { _getSingleButton as getSingleButton };